import moment from 'moment-timezone'
import Image from "next/legacy/image";

import Faq from '../public/faq.png'
import Contact from '../public/contact.png'
import Info from '../public/info.png'
import Router from 'next/router'
import { useState, useEffect, useRef, CSSProperties } from 'react';
import { Client, Gql } from '../envs'
import { useSession } from 'next-auth/react'

export default function Footer () {
    const [hidefooter, set_hidefooter] = useState(true)
    const { data: session } = useSession()
    const get_settings = () => {
        let qu = Gql(`query {
            getSettings{
                bottom_links
            }
        }`);
        let variables = {}
        Client
        .hydrated()
        .then(function (client) {
        client.query({query: qu, fetchPolicy: 'network-only', variables})
        .then(function logData(data) {
            let dt = data.data.getSettings;
            set_hidefooter(dt.bottom_links)
        })
        .catch((err) => {}); });
    }

    useEffect(()=>{
        get_settings()
    },[])

    const renderImage = (i) => {
        return <Image src={i} width={18} height={18}/>
    }

    let user = session;
    return hidefooter == false ? (
        <center>
            <div className='cnt'>
                <div className="terms">
                    <div className="lbq" onClick={()=>Router.push('/faq')}>
                        <div>FAQ</div>
                    </div>
                    <div className="lbq" onClick={()=>Router.push('/terms')}>
                        <div>Terms of Service</div>
                    </div>
                    <div className="lbq" onClick={()=>Router.push('/privacy')}>
                        <div>Privacy Policy</div>
                    </div>
                </div>
                {/*<div className='cp'>
                    &copy;{moment().year()} HelpHero PBC, Inc.
                </div>*/}
            </div>
            <style jsx>{`
                .lbq {
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                }
                .lbq div {
                    margin-left:4px;
                }
                .cnt {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding-top: 12px;
                    font-size: small;
                    color:#6b63fe;
                    text-decoration:underline;
                }
                .terms {
                    text-align: center;
                    font-size: small;
                    padding-top: 0px;
                    padding-bottom:0px;
                }
                .terms a {
                    text-decoration:none;
                    color:#6b63fe;
                }
                .terms a:visited {
                    color:#6b63fe;
                }
                .terms a:hover {
                    color:#6b63fe;
                }
                .terms a:active {
                    color:#6b63fe;
                }
                .cp {
                    text-align: center;
                    font-size: small;
                    padding-top: 6px;
                }


                
                .cnt {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    font-size: small;
                    padding-top:0;
                }
                .terms {
                    text-align:left;
                    display: flex;
                    flex-direction: row;
                }
                .terms div {
                    padding-top:4px;
                    padding-bottom:4px;
                    margin-right:8px;
                }

                .donateContainer.jsx-2482200260 {
                    margin-top: 10px!important;
                    margin-bottom: 10px;
                }

                @media (max-width: 600px) {
                    .cnt {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        font-size: small;
                        padding-top:0;
                    }
                    .terms {
                        text-align:left;
                        display: flex;
                        flex-direction: row;
                    }
                    .terms div {
                        padding-top: ${user && user.user ? '4px' : '6px'};
                        padding-bottom:4px;
                        margin-right:8px;
                    }
                }
            `}</style>
        </center>
    ):(<div style={{ marginBottom:15 }}/>)
}