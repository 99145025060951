import Head from 'next/head'
import Header from "../components/header_c"
import Seemore from "../components/Seemore"
export default function Custom404 () {
    return (
        <>
            <Head>
            <title>HelpHero</title>
            <link rel="icon" href="/favicon.png" />
            <meta name="theme-color" content="#f9b100"></meta>
            <meta name="description" content="Donate and get a video of your actual contribution being made."></meta>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
            </Head>
            <Header page="404"/>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 400,
                marginLeft: 'auto',
                marginRight: 'auto',
                height: '90vh',
                justifyContent: 'space-evenly'
            }}>
                <div style={{
                    paddingTop: '10%',
                    paddingBottom: '10%',
                    textAlign: 'center',
                    fontWeight: 'bold'
                }}>
                    Oops.. The page you are looking is not found..
                </div>
                <Seemore/>
            </div>
        </>
    )
}